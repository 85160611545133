import React from "react"
import LayoutTwo from "../../components/LayoutTwo"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { AsphaltTableOne } from "../../components/AsphaltTableOne"
import { AsphaltTableTwo } from "../../components/AsphaltTableTwo"
import { AsphaltTableOneEn } from "../../components/AsphaltTableOneEn"
import { AsphaltTableTwoEn } from "../../components/AsphaltTableTwoEn"
import SEO from "../../components/SEO"
import { useLanguage } from "../../contexts/LanguageContext"
const Asphalt = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <LayoutTwo>
      <SEO title="Asfalt" />
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter contact-header">
            Asphalt Assortment
          </h2>
        ) : (
          <h2 className="about-header visibility-filter contact-header">
            Asfaltni Asortiman
          </h2>
        )}
        <h2 className="about-header mobile-visible">Asfalt</h2>
      </div>
      <div className="about-content">
        <div className="asphalt-wrapper">
          {/* {selectedLanguage === "EN" ? (
            <div>
              <AsphaltTableOneEn />
            </div>
          ) : (
            <div>
              <AsphaltTableOne />
            </div>
          )} */}
          {/* {selectedLanguage === "EN" ? (
            <div className="asphalt-tabletwo-wrapper">
              <AsphaltTableTwoEn />
            </div>
          ) : (
            <div className="asphalt-tabletwo-wrapper">
              <AsphaltTableTwo />
            </div>
          )} */}

          <div className="asphalt-text-margin"></div>
          <div>
            {selectedLanguage === "EN" ? (
              <h4 className="about-paragraph">
                <br></br>
                Here we attach our{" "}
                <a
                  className="asphalt-text"
                  href="https://drive.google.com/uc?export=download&id=1axZ1ytpM2jdszQiqAI4TtasReK6SjcQO"
                >
                  offer
                </a>{" "}
                , and the general conditions of sale of asphalt are available at
                the following{" "}
                <a
                  className="asphalt-text"
                  href="https://drive.google.com/uc?export=download&id=1sx5ccg-yYLHnqLxFKCAMwZ1gUfRC2O6y"
                >
                  link.
                </a>{" "}
                {/* Opće uvjete prodaje asfalta možete dobiti klikom na{" "} */}
              </h4>
            ) : (
              <h4 className="about-paragraph">
                <br></br>
                Ovdje prilažemo našu{" "}
                <a
                  className="asphalt-text"
                  href="https://drive.google.com/uc?export=download&id=1axZ1ytpM2jdszQiqAI4TtasReK6SjcQO"
                >
                  ponudu
                </a>{" "}
                , a opći uvjeti prodaje asfalta dostupni su na sljedećem linku{" "}
                <a
                  className="asphalt-text"
                  href="https://drive.google.com/uc?export=download&id=1xezLbkfn5VbjH1WcoZ-qHtbMwP0TbDSo"
                >
                  linku.
                </a>{" "}
                {/* Opće uvjete prodaje asfalta možete dobiti klikom na{" "} */}
              </h4>
            )}
          </div>
        </div>
      </div>
    </LayoutTwo>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "ELEMENTMEDIA_a7RIV-0847.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px),1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Asphalt
